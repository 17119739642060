import { useState } from 'react';
import { Image } from "./image";
import {Modal} from './Modal'

export const Gallery = (props) => {
  const[overlay,setOverlay]=useState(false);
  const[selectedImg,setSelectedImg]=useState(null);
  
  return (
    <>
       <Modal overlay={overlay} onClose={()=>setOverlay(false)}>
        <div className='portfolio-details'>
        <img src={selectedImg?.largeImage} style={{width:'100%'}}/>
        {
          selectedImg?.details.map((item,index)=>{
            return(<span key={index+item.title}>    
                    <h3>{item?.title}</h3>
                    <p>
                      {item?.desc}
                    </p>
                </span>)
          })
        }
      
        </div>
      </Modal>
      <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <p>
          RSA Component Screenshots
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-12 col-sm-6 col-md-3 col-lg-4 portfolio-items-img' onClick={()=>{setOverlay(true);setSelectedImg(d)}}>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
    </>
  
  )
}
