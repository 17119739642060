export const Team = (props) => {
  return (
    <div id='team' className=''>
      <div className='container'>
        <div className='col-md-12  section-title text-center'>
          <h2>The innovators</h2>
          <p>
          Meet the team behind RSA
          </p>
  
        </div>
        <div className='col-md-12  section-title'>
        <p>
          My name is Mike O’Neil and I have owned my own software companies since I was 23; I have been developing / designing software for the best part of 40 years now.
          Chances are, if you sold or bought a house in the last 18 years, your conveyancer used software I designed and developed.
          Fifteen years ago, I decided it was time to do something that did not require me being away from home yet made me as much as I needed to live on, and the only thing I could come up with was punting on horses.
          I wasn’t going to jump straight in; I figured a great test of my abilities with computers and mathematics would be to create a program that would guarantee success. Not a single friend believed it was possible, which is probably why I didn’t quit after the first five years; when someone says it cannot be done, all I hear is no one else could do it.

          In that time we have spent tens of thousands of hours. Myself and Hamid Ghasempour, without doubt the best software engineer I ever employed in my commercial life, spent that time collecting every bit of data we could, writing 1,000’s of algorithms, designing and ditching 100’s of calculators.

          Truth is, if I had known it would take this long and cost what it has, I never would have started; never let it be said that I am a quitter – whatever the cost.
          
          </p>

        </div>
        <div id='row'>
          <div className="col-md-3 col-sm-6 "></div>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                    <div className='caption'>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
            <div className="col-md-3 col-sm-6 "></div>
        </div>
      </div>
    </div>
  )
}
