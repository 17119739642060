export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 intro-text col-sm-12 '>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='#pricing'
                  className='btn btn-custom btn-lg page-scroll'>
                    Join Now
                  </a>
              </div>
              <div className="col-md-6 col-sm-12 powerd-by-section">
              <div className="d-flex flex-column">
                
                < div className="apps">
                  <img src="img/app-icons-group.png" alt="" className="feature-tool-bar"/>
                  <h3>Six unique apps in RSA</h3>
                </div>
                <div className="vendors">
                <h3>Powered by </h3>
                  <div className="vendors-icon">
                  <img src="/img/poweredby/betfair.png" alt=""/>
                    <img src="/img/poweredby/elastic.png" alt=""/>   
                  </div>
                               
                </div>
              
              </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

