import { useState } from 'react';
export const Features = (props) => {
  const [selected,setSelected]=useState('')
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title col-12'>
          <h2>Powerful Features</h2>
          <p>RaceStatsApp has six apps in one – giving you access to advanced data analysis and clear visuals not available anywhere else, plus direct link to Betfair for real time odds and placing bets.</p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-12 col-md-3'>
                  {' '}
                  {d.link ? <a href={d.link} target='_blank' rel="noreferrer"><img src={d.icon} alt={d.title} /></a> : <img src={d.icon} alt={d.title} />}                                    
                  <h3 className="feature">{d.link ? <a href={d.link} target='_blank' rel="noreferrer">{d.title}</a> : `${d.title}`}</h3>
                    {
                          props.screenSize.dynamicWidth < 768 ?   <div className="feature-text">{d.text}</div> : <div className="feature-text">{d.text.length>220 ? selected=== `${d.name}-${i}` ? d.text : `${d.text.substring(0,220)} ` : d.text }{d.text.length>220 && <strong className='more' onClick={()=>setSelected(selected=== `${d.name}-${i}` ? '' :`${d.name}-${i}`)}>{selected=== `${d.name}-${i}` ? ' show less' : ' ...show more'}</strong>}</div> 
                    }
                                   
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
