import { useState } from "react"
export const Testimonials = (props) => {
  const [selected,setSelected]=useState('')
  const TextTreashold =  310;

  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>What our customers say</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src={d.img} alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      {
                        props.screenSize.dynamicWidth < 768 ?   
                        <div className="testimonial-text">{d.text}</div> 
                        : 
                        <div className="testimonial-text">{d.text.length> TextTreashold ? selected=== `${d.name}-${i}` ? d.text : `${d.text.substring(0, TextTreashold)} ` : d.text }</div>
                      }
                      {
                        props.screenSize.dynamicWidth >768 && <>{d.text.length > TextTreashold ? 
                        <strong className='more' onClick={()=>setSelected(selected=== `${d.name}-${i}` ? '' :`${d.name}-${i}`)}>{selected=== `${d.name}-${i}` ? 'show less' : 'show more'}</strong>:<strong className='more' style={{visibility:'hidden'}}>1</strong>
                        }</>
                      }
                      <div className='testimonial-meta'> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}

                <div className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image-bg'>
                      {' '}
                      <img src='https://review.goalprofits.com/wp-content/uploads/2019/05/goal-profits-betting-system-reviews.jpg' alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                        RaceStatsApp has redefined horse racing betting by focusing on odds data rather than horse, jockey or trainer form.

                        Although the app has been around for the past four years, the idea and development has been going on for more than ten years to get it to where it is today.

                        ..<a href="https://review.goalprofits.com/racestatsapp/" target='_blank'>show more</a>
                    </div>
                  </div>
                </div>            
        </div>
      </div>
    </div>
  )
}
