

const Switch=({isChecked,toggleChecked,handleChange})=>{

    return( <div className="switch">
            <label className="flags">
            <a href="https://vexter.co.uk/" target='_blank'><img src="img/flags/uk.png" alt=""/></a> UK & IRE
            {/* Change to {this.props.title} and you can set the label text in a higher level component */}
            </label> 
            <span>
            <input 
                type="checkbox"
               
                checked={isChecked}
                onChange={handleChange}
            />
            <button
                className="slider"
                type="button"
                onClick={toggleChecked}>
            </button>
            </span>
        <label className="flags">
        AU <a href="https://vexter.com.au/" target='_blank'><img src="img/flags/au.png" alt=""/></a>
        {/* Change to {this.props.title} and you can set the label text in a higher level component */}
        </label>                
</div>  )
}

  export default Switch;