

import './modal.css'
const stopProp = (e) => {
  e.stopPropagation();
};



export const Modal = ({overlay, onClose,children}) => {
  return (
    <>
      <div className={overlay ? "overlay_shown" : "overlay_hidden"}>
      <div className="overlay_background" onClick={onClose}>
      <div className="overlay_card" onClick={(e) => stopProp(e)}>
        {children}
      </div>
      </div>
      </div>
    </>
  );
};



