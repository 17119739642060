import { useState, useEffect } from "react";
import {About,Contact,Gallery,Header,Team,Testimonials,Features,Pricing,Navigation} from '../components'
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
    })
  }
  
  useEffect(() => {
    window.addEventListener('resize', setDimension);
    
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  return (
    <div>
      <Navigation width={screenSize.dynamicWidth}/>
      <Header data={landingPageData.Header}  />
      <Features data={landingPageData.Features} screenSize={screenSize}/>
      <About data={landingPageData.About} />
      <Pricing data={landingPageData.Pricing} screenSize={screenSize}/>
      <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} screenSize={screenSize}/>
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};


