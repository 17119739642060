import { useState } from "react";
import PricingCard from "./PricingCard"
import Switch from "./Switch"
export const Pricing = (props) => {
  const[country,setCountry]=useState('uk');
  let data=props.data && props.data.filter((item)=>item.country===country);
  let styles={}
  if(data?.length===2 && props.screenSize.dynamicWidth>768){
    styles={
      display:'flex',
      justifyContent:'center'
    }
  }
  return (
    <div id='pricing' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Pricing</h2>
          <div className="pricing-user-profile">
            <figure>
              <img loading="lazy" src="img/features/user-settings.png" alt=""/>
            </figure>

            <p>In User Settings you have total control over your subscription</p>
            <ul>
              <li>Manage subscription plans- including upgrades and cancellations</li>
              <li>Amend payment dates</li>
              <li>Change payment method/card</li>
            </ul>
          </div>
        </div>
        <p>
          For information on pricing and to join up please click on the buttons below:
        </p>
        <div className="switch-container">
        <Switch
          isChecked={country==='au'}
       
          toggleChecked={() => setCountry(country==='uk' ? 'au':'uk')}
        
        />

        </div>
   
        <div className='row' style={styles}>
          {props.data && data.map((d, i) => (
            <div key={`${d.title}-${i}`} className='col-md-4'>
             <PricingCard 
              pricingData={d}
             />
          </div>
              ))
            }
        </div>
      </div>
    </div>
  )
}
