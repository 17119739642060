
import { Home,Promotions } from "./pages";
import {useEffect} from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import "./App.css";

const hash_routes = ["features", "about", "pricing", "portfolio", "testimonials", "team", "contact", "training"]

let routes = [
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/PromoApr24",
    element: <Home/>,
    //element: <Promotions/>,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  }
];

hash_routes.forEach(r => {
  routes.push({
    path: `/${r}`,
    element: <Navigate to={`/#${r}`} />,
  });
});

const router = createBrowserRouter(routes);
const App = () => {
  const scrollToHashElement = (hash) => {
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Check if the current URL hash (without the '#') matches any item in hash_routes and scroll to it
  useEffect(() => {
    if(window.location.hash ){
      const currentHash = (window.location.hash || '#').substring(1); // Remove the '#' from the hash
      console.log('currentHash,currentHash', currentHash)
      if (hash_routes.includes(currentHash)) {
        setTimeout(() => {
          scrollToHashElement(currentHash);
        }, 500);       
      }
    }
   
  }, [window.location.hash]);
  return (
      <RouterProvider router={router} />
  );
};

export default App;
